import React, { useMemo, useReducer, useRef }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AddTaskFromPlan  from '@components/Task/AddTaskFromPlan'
import Button           from '@components/Button'
import Card             from '@components/Card'
import Column           from '@components/Column'
import Dropdown         from '@components/Dropdown'
import ExpectedDateForm from '@components/Report/ExpectedDateForm'
import { Filter }       from '@components/Column/Filter'
import Task             from '@components/Task'
import TaskForm         from '@components/Task/Form'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import { REPORT_ACTIONS, TASK_ACTIONS, taskReducer } from '@reducers/index'
import { TaskContextProvider } from '@context/TaskContext'

interface IReportTasks {
  maxHeight?: string
  grow?:      boolean
  height?:    string
}

const ReportsTasks: React.FC<IReportTasks> = ({
  maxHeight = '100%',
  grow      = true,
  height    = '100%',
}) => {

  const {
    initAPI,
    showModal,
    i18n,
    CONSTANTS,
    fetchApi,
    closeModal,
    current_user
  } = useGlobalContextState()

  const {
    report,
    setReport,
    updateMethods,
    reportDispatch
  } = useReportContextState()

  const { TASK } = CONSTANTS

  const [_state, dispatch] = useReducer(taskReducer, { report, setReport, fetchApi, closeModal, updateMethods })
  const ongoingRef1 = useRef(null)
  const ongoingRef2 = useRef(null)

  const API = initAPI({ reportId: report.id })

  const addReportExpectedDate = (data, fieldset) => reportDispatch({
    type:      REPORT_ACTIONS.UPDATE,
    data,
    report,
    fieldset,
    callbacks: [
      () => API.fetchReport().then(updateMethods.report),
      closeModal
    ]
  })
  // const createTask = data => dispatch({
  //   type:      TASK_ACTIONS.CREATE, data, callbacks: [
  //     () => API.fetchReport().then(updateMethods.report),
  //     () => API.fetchConversations().then(updateMethods.conversations),
  //   ]
  // })

  const addForm = data => dispatch({
    type: TASK_ACTIONS.CREATE_FORM, report, data
  })

  const addTaskForm = ()   => {
    if (!report.expected_date && report.permissions.must_set_expected_date) {
      showModal({
        title:   i18n.t('todo.actions.add_task'),
        content: <ExpectedDateForm callback={addReportExpectedDate} />
      })
    } else {
      showModal({
        title:   i18n.t('todo.actions.add_task'),
        content: <TaskForm
          report        = {report}
          updateMethods = {updateMethods}
          addForm       = {addForm}
        />
      })
    }
  }


  const headerActions = useMemo(() => {
    if (report.permissions.can_create_task) {
      return [report.maintenance_plan
        ? <Dropdown
          icon  = {<FontAwesomeIcon icon="plus" />}
          color = 'var(--rep-primary)'
          hover = 'var(--rep-primary-light)'
          options = {[
            {
              icon:    <FontAwesomeIcon icon="plus" />,
              color:   'var(--rep-success)',
              content: i18n.t('todo.actions.create'),
              click:   addTaskForm
            },
            {
              icon:    <FontAwesomeIcon icon="clipboard" />,
              color:   'var(--rep-primary)',
              content: i18n.t('todo.actions.add_from_plan'),
              click:   () => showModal({
                title:   i18n.t('todo.actions.add_task_from_plan'),
                content: <AddTaskFromPlan report={report} reportDispatch={reportDispatch} />
              })
            }
          ]}
        >
          {i18n.t('shared.actions.add')}
        </Dropdown>
        : <Button
          key   = 'create-task'
          icon  = {<FontAwesomeIcon icon="plus" />}
          color = 'var(--rep-primary)'
          hover = 'var(--rep-primary-light)'
          click = {addTaskForm}
        >
          {i18n.t('actions.add')}
        </Button>
      ]
    } else {
      return []
    }
  }, [report])

  const emptyCard = useMemo(() =>
    <Card border='var(--rep-neutral-primary-light)' togglable={false}>
      <Style.EmptyColumn>
        <p><FontAwesomeIcon icon="mug-hot" /></p>
        {i18n.t('todo.page.no_todos')}
      </Style.EmptyColumn>
    </Card>, [])

  const emptyFilters = useMemo(() =>
    <Card border='var(--rep-neutral-primary-light)' togglable={false}>
      <Style.EmptyColumn>
        <p><FontAwesomeIcon icon="ghost" /></p>
        {i18n.t('todo.page.no_filtered_todos')}
      </Style.EmptyColumn>
    </Card>, [])

  const filters = useMemo(() => new Filter({
    references: {
      status: TASK.STATUS
    },
    filters: [
      {
        id:        TASK.STATUS.NEW,
        name:      i18n.t('todo.status.new'),
        icon:      <FontAwesomeIcon icon={['far', 'paper-plane']} />,
        reference: 'status'
      },
      {
        id:        TASK.STATUS.ASSIGNED,
        name:      i18n.t('todo.status.assigned'),
        icon:      <FontAwesomeIcon icon={['far', 'user']} />,
        reference: 'status'
      },
      {
        id:        TASK.STATUS.DONE,
        name:      i18n.t('todo.status.done'),
        icon:      <FontAwesomeIcon icon={['far', 'eye']} />,
        reference: 'status'
      },
      {
        id:        TASK.STATUS.CLOSED,
        name:      i18n.t('todo.status.closed'),
        icon:      <FontAwesomeIcon icon='check' />,
        reference: 'status'
      },
    ],
    totalFilteredCount: report.total_filtered_tasks,
    perPage:            20,
    totalPages:         report.total_tasks_pages,
    fetch:              API.fetchTasks,
    pageCallback:       data => updateMethods.moreTasks(data),
    callback:           updateMethods.tasks
  }), [])

  const updateUserOngoingTask = (task) => {
    if (ongoingRef1.current && ongoingRef2.current) {
      if (task.action === TASK.ACTIONS.START) {
        current_user.ongoing_task = false
        // console.log('onoing state after stop', current_user.ongoing_task)
      } else if (task.action === TASK.ACTIONS.STOP) {
        current_user.ongoing_task = true
        // console.log('onoing state after start', current_user.ongoing_task)
      }
    } else if (ongoingRef1.current && !ongoingRef2.current) {
      ongoingRef2.current = true
    } else {
      ongoingRef1.current = true
    }
  }

  return(
    <Column
      togglable
      open
      paginated
      withFilters
      maxHeight        = {maxHeight}
      height           = {height}
      grow             = {grow}
      overflow         = 'auto'
      title            = {i18n.t('todo.todos')}
      totalCount       = {report.total_filtered_tasks}
      currentCount     = {report.tasks.length}
      totalFiltered    = {report.total_filtered_tasks}
      totalPages       = {report.total_tasks_pages}
      headerActions    = {headerActions}
      showEmptyContent = {!report.total_tasks}
      emptyContent     = {emptyCard}
      showEmptyFilters = {!!report.total_tasks && !report.total_filtered_tasks}
      emptyFilters     = {emptyFilters}
      filters          = {filters}
    >
      {report.tasks.map(task =>
        <TaskContextProvider
          key        = {task.id}
          serverTask = {task}
        >
          <Task
            key                   = {task.id}
            report                = {report}
            updateMethods         = {updateMethods}
            updateUserOngoingTask = {updateUserOngoingTask}
            current_user          = {current_user}
          />
        </TaskContextProvider>
      )}
    </Column>
  )
}

export default ReportsTasks
