import React, { useReducer } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button  from '@components/Button'
import Callout from '@components/Callout'
import Card    from '@components/Card'
import Tag     from '@components/Tag'

import { useGlobalContextState } from '@context/GlobalContext'

import * as TaskStyle from '@components/Task/style'
import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

const ExpectedTaskCard = ({ task, fetchPage }) => {

  const { i18n, fetchApi, current_company } = useGlobalContextState()

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi
  })

  const generateMaintenanceReport = () => {
    const date = new Date(task.date)
    date.setDate(date.getDate() + task.offset)

    dispatch({
      type: MAINTENANCE_ACTIONS.PLANIFY,
      data: {
        maintenance_id: task.maintenance_id,
        date:           date.toISOString(),
        original_date:  date.toISOString(),
        notify:         false,
        auto_assign:    true
      },
      callbacks: [() => fetchPage(1)]
    })
  }

  return(
    <Card
      togglable = {false}
      headerTop = {
        <TaskStyle.TaskHeader>
          <TaskStyle.TaskStatus status='done'>
            <FontAwesomeIcon icon="clock-rotate-left" />
            {i18n.t('maintenance.recurrence_title')}
          </TaskStyle.TaskStatus>
          <div></div>
          <TaskStyle.TaskTopActions>
            <Tag
              icon           = {<FontAwesomeIcon icon="calendar-xmark" />}
              color          = 'var(--rep-warning)'
              background     = 'var(--rep-warning-light)'
              tooltipContent = "Expected for"
            >
              {new Date(task.date).toLocaleDateString(i18n.locale)}
            </Tag>
            {!!task.duration &&
              <Tag
                icon           = {<FontAwesomeIcon icon="hourglass" />}
                color          = 'var(--rep-neutral-primary)'
                background     = 'var(--rep-neutral-light)'
                hover          = 'var(--rep-neutral-primary-light)'
                tooltipContent = {i18n.t('todo.expected_duration')}
              >
                {task.duration_string}
              </Tag>
            }
          </TaskStyle.TaskTopActions>
        </TaskStyle.TaskHeader>
      }
      header={
        <>
          <TaskStyle.TaskTitle>{task.name}</TaskStyle.TaskTitle>
          <TaskStyle.TaskText>{task.description}</TaskStyle.TaskText>
        </>
      }
    >
      {/* <div style={{color: 'var(--rep-warning)'}}> */}
      <Callout
        color="var(--rep-warning)"
        background="var(--rep-warning-light)"
        icon={<FontAwesomeIcon icon="calendar-xmark" />}
        border='transparent'
        marginY='S'
      >
        {i18n.t('todo.previous_maintenance_not_closed')}
      </Callout>
      {current_company.permissions.tech_can_create_tasks &&
        <Button
          icon       = {<FontAwesomeIcon icon="play" />}
          background = "var(--rep-success-light)"
          color      = "var(--rep-success)"
          click      = {generateMaintenanceReport}
        >
          {i18n.t('todo.actions.start_anyway')}
        </Button>
      }
      {/* </div> */}
      <div style={{display: 'flex', gap: '8px', overflowX: 'scroll', scrollbarWidth: 'none', marginTop: '8px', marginBottom: '8px'}}>
        <Tag
          // border='var(--rep-warning)'
          color      = 'var(--rep-neutral)'
          background = 'var(--rep-neutral-light)'
          icon       = {task.maintainable_type === 'Amenity'
            ? <FontAwesomeIcon icon="gear" />
            : task.maintainable_type === 'Unit'
              ? <FontAwesomeIcon icon="location-dot" />
              : null}
        >
          {task.maintainable.name}
        </Tag>
        <Tag
          color      = 'var(--rep-neutral)'
          background = 'var(--rep-neutral-light)'
          icon       = {<FontAwesomeIcon icon="file-contract" />}
        >
          {task.plan_name}
        </Tag>
      </div>
    </Card>
  )
}
export default ExpectedTaskCard
