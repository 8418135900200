import React, { forwardRef, useEffect, useMemo, useState }   from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WithTooltip from '@components/WithTooltip'
import Input       from '../Input'

import * as FormStyle            from '../FormStyles'
import * as Style                from './style'

import DatePickerContainerProps  from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

import { registerLocale, setDefaultLocale } from 'react-datepicker'

import { de }   from 'date-fns/locale/de'
import { enGB } from 'date-fns/locale/en-GB'
import { fr }   from 'date-fns/locale/fr'
import { nl }   from 'date-fns/locale/nl'

registerLocale('en', enGB)
registerLocale('de', de)
registerLocale('fr', fr)
registerLocale('nl', nl)


const DatePicker2: React.FC<DatePickerContainerProps> = ({
  name,
  date,
  label,
  showTime,
  callback,
  tooltip,
  minDateTime,
  maxDateTime,
  disableYear = false,
  allowClear  = true,
  required    = false,
  disabled    = false,
  mode        = 'single',
  timeAsSelect = true,
  marginY,
  onlyTime    = false,
  inline      = false,
  customInput,
}) => {
  const { i18n } = useGlobalContextState()

  setDefaultLocale(i18n.locale)

  const [selectedDate, setSelectedDate] = useState(Array.isArray(date) ? date.map(d => d ? new Date(d) : null) : date ? new Date(date) : null)

  // Will filter selectable dates between minDateTime and maxDateTime (if they are set)
  const filterPassedTime = (time) => {
    if (!minDateTime && !maxDateTime) return true

    const selectedDate = new Date(time)
    let availableTime = true

    // This is a DateTime range, not a time range (ie: between 01/01/2025:11h30 and 01/04/2025:17h30)
    // Not everyday between 08:00 and 11:00 (but the component could be improved to handle that if needed)
    if (minDateTime && maxDateTime) {
      availableTime = minDateTime.getTime() <= selectedDate.getTime() && maxDateTime.getTime() >= selectedDate.getTime()
    } else if (minDateTime) {
      availableTime = minDateTime.getTime() <= selectedDate.getTime()
    } else if (maxDateTime) {
      availableTime = maxDateTime.getTime() >= selectedDate.getTime()
    }


    return availableTime
  }

  useEffect(() => {
    setSelectedDate(Array.isArray(date) ? date.map(d => new Date(d)) : date ? new Date(date) : null)
  }, [date])

  // Clear if minDateTime is set and selectedDate is before minDateTime
  useEffect(() => {
    if (minDateTime && selectedDate && selectedDate < minDateTime) {
      setSelectedDate(null)
    }
  }, [minDateTime])

  // Clear if maxDateTime is set and selectedDate is after maxDateTime
  useEffect(() => {
    if (maxDateTime && selectedDate && selectedDate > maxDateTime) {
      setSelectedDate(null)
    }
  }, [maxDateTime])

  /**
    * This function returns the date format to display according to the locale
    * @returns a string, DD/MM/YYY for instance in french.
  */
  const localizedDatePattern = useMemo(() => {
    const format = showTime
      ? onlyTime
        ? {
          hour:   '2-digit',
          minute: '2-digit'
        }
        : {
          year:   'numeric',
          month:  '2-digit',
          day:    '2-digit',
          hour:   '2-digit',
          minute: '2-digit'
        }
      : {
        year:  'numeric',
        month: '2-digit',
        day:   '2-digit',
      }
    // Setting locale to 'en-GB' to have the DD/MM/YYYY format, because 'en' defaults to 'en-US'
    return new Intl.DateTimeFormat(i18n.locale == 'en' ? 'en-GB' : i18n.locale, format)
      .formatToParts(new Date())
      .map((obj) => {
        switch (obj.type) {
          case 'day':     return 'dd'
          case 'month':   return 'MM'
          case 'year':    return 'YYYY'
          // case 'literal': return obj.value === ':' ? showTime ? ':' : null : obj.value
          case 'hour':    return 'HH'
          case 'minute':  return 'mm'
          default: return obj.value
        }})
      .join('')
  }, [i18n.locale, showTime])

  // Custom input for the datepicker
  const DefaultCustomInput = forwardRef(({ value, onClick, className }, ref) => (
    <Input
      ref          = {ref}
      type         = "text"
      name         = {`${name}-input`}
      click        = {onClick}
      icon         = {<FontAwesomeIcon icon={onlyTime ? ['far', 'clock'] : ['far', 'calendar']} color={!complete && required ? 'var(--rep-warning)' : 'var(--rep-neutral-primary)'} />}
      placeholder  = {i18n.t('shared.actions.select_date')}
      defaultValue = {value}
      required     = {required}
      readOnly
    />
  ))
  DefaultCustomInput.displayName = 'DatePickerInput'

  // Callback triggered when a date is selected
  // Returns an array of dates if mode is range, a single date otherwise
  const changeDate = (newDate) => {
    setSelectedDate(newDate)
    if (!callback) return

    if (newDate) {
      if (mode === 'range') {
        if (newDate.filter(d => d).length === 0) {
          callback(null)
        } else if (showTime && newDate.filter(d => d).length === 1) {
          callback([newDate[0], newDate[0]])
        } else if (newDate.filter(d => d).length === 2) {
          callback(newDate)
        }
      }
      else { callback(newDate) }
    }
  }

  // Returns true if the date is complete (ie: a range of 2 dates or a single date)
  // Used only for styling the input
  const complete = useMemo(() => {
    if (mode === 'range') {
      return !!selectedDate && selectedDate.filter(d => d).length === 2
    }
    return !!selectedDate
  }, [selectedDate])

  return (
    <Style.DatePickerContainer marginY={marginY}>
      <Style.DatePickerWrapper
        id                = {name}
        name              = {`${name}-picker`}
        selectsRange      = {mode === 'range'}
        popperContainer   = {Style.Popper}
        calendarContainer = {Style.Calendar}
        onChange          = {changeDate}
        startDate         = {mode === 'range' && selectedDate ? selectedDate[0] : null}
        endDate           = {mode === 'range' && selectedDate ? selectedDate[1] : null}
        selected          = {mode !== 'range' && selectedDate ? selectedDate    : null}
        minDate           = {minDateTime}
        maxDate           = {maxDateTime}
        filterTime        = {filterPassedTime}
        locale            = {(i18n.locale === 'en' && enGB) ||
            (i18n.locale === 'fr' && fr) ||
            (i18n.locale === 'nl' && nl) ||
            (i18n.locale === 'de' && de)}
        isClearable        = {!disabled && allowClear}
        required           = {required}
        readOnly           = {disabled}
        showTimeInput      = {showTime && !timeAsSelect}
        showTimeSelect     = {showTime && timeAsSelect}
        showTimeSelectOnly = {onlyTime && timeAsSelect}
        timeIntervals      = {15}
        inputReadOnly      = {true} // Writing in the input is not allowed
        inline             = {inline}
        complete           = {complete}
        customInput        = {customInput
          ? customInput
          : <DefaultCustomInput className="datepicker-custom-input" />
        }
        dateFormat        = {disableYear ? localizedDatePattern.replace('/YYYY', '') : localizedDatePattern}
        placeholderText   = {i18n.t('shared.actions.select_date')}
        shouldCloseOnSelect
        showYearDropdown
        scrollableYearDropdown
        showMonthDropdown
      />
      <input
        type  = "hidden"
        name  = {name}
        value = {selectedDate ? mode === 'range' ? selectedDate.map(d => d ? new Date(d).toISOString() : null).join(',') : new Date(selectedDate)?.toISOString() : ''}
      />

      {label && <Style.Label htmlFor={name}>{label}</Style.Label>}
      {!!tooltip &&
          <FormStyle.Tooltip>
            <WithTooltip content={tooltip}>
              <FontAwesomeIcon icon="circle-question" />
            </WithTooltip>
          </FormStyle.Tooltip>
      }
    </Style.DatePickerContainer>
  )
}

export default DatePicker2
